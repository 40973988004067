@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;600&display=swap');

:root {
  --bc-niklas: #00acff;
  --bc-niklas-hover: #00a9e4;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  max-width: 200px;
}

@media (min-width:992px) {
  .dropdown-menu {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.strip-icons {
  height: 24px;
  width: auto;
}

.nav-icons {
  height: 32px;
  width: auto;
}

.navbar-nav .dropdown-menu {
  border: 0;
  min-width: max-content;
  padding-left: 6px;
  padding-right: 6px;
}

.text-niklas,
.text-niklas>a {
  color: var(--bc-niklas) !important;
  fill: var(--bc-niklas) !important;
}

.text-sahow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.btn {
  --bs-btn-border-radius: .2rem;
  border: 0;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-bg: var(--bc-niklas);
  --bs-btn-hover-bg: var(--bc-niklas-hover);
  --btn-border-width: 0;
}

.btn-xl {
  --bs-btn-padding-y: 1.25rem;
  --bs-btn-padding-x: 2rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-line-height: 1.25rem;
  --bs-btn-border-radius: 0.25rem;
}

.bg-primary {
  background-color: var(--bc-niklas) !important;
}

@media (max-width: 767px) {
  .btn-mobile-sm {
    padding: .25rem .5rem;
    /* This is the padding of .btn-sm in Bootstrap */
    font-size: .875rem;
    /* This is the font-size of .btn-sm in Bootstrap */
    line-height: 1.5;
    /* This is the line-height of .btn-sm in Bootstrap */
    border-radius: .2rem;
    /* This is the border-radius of .btn-sm in Bootstrap */
  }
}

.gradient-text {
  background: linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%);
  color: #9D8EEE;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-primary {
  border-color: var(--bc-niklas);
}


.divider {
  height: 3rem;
  background-color: #fff;
  border: solid rgba(0, 0, 0, .1);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1em rgba(0, 0, 0, .1), inset 0 0.1em 0.5em rgba(0, 0, 0, .1);
}

/* HERO START */
.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 400px;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}

.content {
  position: relative;
  z-index: 1;
}

/* FEATURES */
.feature-icons {
  max-width: 96px;
  margin-right: 20px;
}

/* SERVICES */
.bg-transparent {
  --bs-list-group-bg: transparent;
}

/* QUOTE */


.card-header-min-height {
  min-height: 100px;
}

.card-body-min-height {
  min-height: 250px;
}

.bg-niklas-gradient {
  background: linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%);
}

.bg-special-gradient {
  background: linear-gradient(135deg, #FFCC33 0%, #E233FF 100%);
}

.btn-niklas:hover,
.btn-niklas.active {
  background: linear-gradient(180deg, #DCE35B 0%, #45B649 100%);
  /* background: linear-gradient(180deg, #a8e063 0%, #56ab2f 100%); */
}

.btn-niklas:hover span,
.btn-niklas.active span {
  background: #fff;
  -webkit-background-clip: text;
}